<template>
  <v-main app>
    <!-- Provides the application the proper gutter -->

    <!-- fill-height class add for center -->
    <!-- <v-skeleton-loader
      v-if="getThemeMode.isLoading"
      :loading="getThemeMode.isLoading"
      height="100vh"
      width="100%"
      color="primary"
      type="table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article,table-heading, list-item-two-line,list-item-two-line, image, table-tfoot,list-item-avatar-three-line, image, article"
    ></v-skeleton-loader>-->
    <v-container class="ContainerApp">
      <!-- If using vue-router -->

      <!-- <customizer /> -->
      <v-expand-transition mode="out-in">
        <router-view />
      </v-expand-transition>
    </v-container>
    <!-- <div class="flex-grow-1"></div> -->
    <!-- <app-footer></app-footer> -->
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  // components: {
  //   AppFooter: () => import("./Footer"),
  //   // Customizer: () => import("@/layouts/common-drawer/Customizer"),
  // },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
};
</script>

<style lang="scss">
.ContainerApp {
  height: calc(100vh - 60px);
  overflow: auto;
  max-width: initial !important;
}

.ContainerApp::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* display: none; */
}

.ContainerApp::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.ContainerApp::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.ContainerApp::-webkit-scrollbar-thumb:active {
  background-color: #999999;
}

.ContainerApp::-webkit-scrollbar-track {
  /* background: #e1e1e1; */
  border-radius: 4px;
}

.ContainerApp:hover .ContainerApp::-webkit-scrollbar {
  display: initial;
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

// @media screen and (max-width: 1400px) {
//   .ContainerApp {
//     height: auto;
//   }
// }
</style>
